<template>
    <div v-if="!$isMobile">
        <div>
            <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
                <a-col :span="5" class="l_con">
                    <a-card title="机构数据" style="height: 240px;">
                        <div @click="toLink('/agency/institutions')" class="greyCon" style="background-color: #F5F4F9; cursor: pointer;">
                            <a-statistic title="机构总数(家)"
                                :value="numberOfInstitutionsVo.numberOfInstitutions > 0 ? numberOfInstitutionsVo.numberOfInstitutions : '0'">
                                <template #suffix>
                                    家
                                </template>
                            </a-statistic>
                            <!-- <p>开通CRM数 <span>{{ numberOfInstitutionsVo.numberOfOpening > 0 ?
                                numberOfInstitutionsVo.numberOfOpening : '-'
                            }}</span></p> -->
                        </div>
                    </a-card>
                </a-col>
                <a-col :span="19">
                    <a-card title="留服客资数据" style="height: 240px;" class="r_lfcon">
                        <template #extra>
                            <a-range-picker class="page-btm" style="width: 230px; background: #F3F4F8;"
                                v-model:value="value" :placeholder="['开始时间', '结束时间']"
                                @change="getCustomerData" /></template>
                        <div @click="toLink('/newCustomer/institutionsCustomer')" class="greyCon" style="background-color: #EFF7FF; cursor: pointer;">
                            <a-statistic title="新增客资(条)"
                                :value="customerDataVo.currentNew > 0 ? customerDataVo.currentNew : ''">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentNew" :LastData="customerDataVo.lastNew">
                            </chainIndex>

                        </div>
                        <div @click="toLink('/newCustomer/allocationCustomer')" class="greyCon" style="background-color: #FFF4F5; cursor: pointer;">
                            <a-statistic title="分配客资(条)"
                                :value="customerDataVo.currentAllocation > 0 ? customerDataVo.currentAllocation : ''">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentAllocation"
                                :LastData="customerDataVo.lastAllocation">
                            </chainIndex>
                        </div>
                        <div @click="toLink('/order/institutionsOrder')" class="greyCon" style="background-color: #EFF7FF; cursor: pointer;">
                            <a-statistic title="签单数(签)"
                                :value="customerDataVo.currentNumberOfSignedOrders > 0 ? customerDataVo.currentNumberOfSignedOrders : '0'">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentNumberOfSignedOrders"
                                :LastData="customerDataVo.lastNumberOfSignedOrders">
                            </chainIndex>
                        </div>
                        <div @click="toLink('/order/institutionsOrder')" class="greyCon" style="background-color: #FFF4F5; cursor: pointer;">
                            <a-statistic title="签单金额(元)"
                                :value="customerDataVo.currentSignedAmount > 0 ? customerDataVo.currentSignedAmount : '0'">
                                <template #prefix>
                                    ￥
                                </template><template #suffix>
                                    元
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentSignedAmount"
                                :LastData="customerDataVo.lastSignedAmount">
                            </chainIndex>

                        </div>
                        <div @click="toLink('/order/transactions')" class="greyCon" style="background-color: #EFF7FF; cursor: pointer;">
                            <a-statistic title="回款金额(元)"
                                :value="customerDataVo.currentRevenue > 0 ? customerDataVo.currentRevenue : '0'">
                                <template #prefix>
                                    ￥
                                </template>
                                <template #suffix>
                                    元
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentRevenue" :LastData="customerDataVo.lastRevenue">
                            </chainIndex>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row style="margin: 20px 0;" :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
                <a-col :span="15">
                    <a-card title="客资趋势">
                        <template #extra>
                            <a-range-picker @change="drawLine" class="page-btm" style="width: 230px; background: #F3F4F8;"
                                v-model:value="lineValue" :placeholder="['开始时间', '结束时间']" /></template>
                        <div id="myLine" :style="{ width: '100%', height: '400px' }"></div>
                    </a-card>
                </a-col>
                <a-col :span="9">
                    <a-card title="客户来源" class="r_lfcon">
                        <template #extra>
                            <a-range-picker @change="getCustomerSource" class="page-btm"
                                style="width: 230px; background: #F3F4F8;" v-model:value="pieValue"
                                :placeholder="['开始时间', '结束时间']" /></template>
                        <div id="myChart" :style="{ width: '100%', height: '400px' }"></div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }" class="f_con">
                <a-col :span="12">
                    <a-card title="员工有效客资排名">
                        <template #extra>
                            <a-range-picker @change="getAssignCustomersData()" class="page-btm"
                                style="width: 230px; background: #F3F4F8;" v-model:value="assignCustomersValue"
                                :placeholder="['开始时间', '结束时间']" /></template>
                        <a-table size="middle" :pagination="false" bordered :dataSource="dataSource" :columns="columns">
                            <template #bodyCell="{ column, record, index }">
                                <!-- 序号 -->
                                <template v-if="column.key === 'index'">
                                    <span class="icon_num icon_num_all" v-if="index < 3">{{ index + 1 }}</span>
                                    <span class="icon_num_all" v-else>{{ index + 1 }}</span>
                                </template>
                                <!-- 环比 上下icon -->
                                <template v-if="column.key === 'ringNumber'">
                                    <p>
                                        <span v-if="record[column.key] > 0" style="color: #eb2f96;">
                                            <UpCircleTwoTone two-tone-color="#eb2f96" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else-if="record[column.key] < 0" style="color: #41dd57;">
                                            <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </p>
                                </template>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
                <a-col :span="12">
                    <a-card title="机构接收客资排名" class="r_lfcon">
                        <template #extra>
                            <a-range-picker @change="getReceivingCustomersData()" class="page-btm"
                                style="width: 230px; background: #F3F4F8;" v-model:value="receivingCustomersValue"
                                :placeholder="['开始时间', '结束时间']" /></template>
                        <a-table size="middle" :pagination="false" bordered :dataSource="receivingCustomersDataSource"
                            :columns="receivingCustomersColumns">
                            <template #bodyCell="{ column, record, index }">
                                <!-- 序号 -->
                                <template v-if="column.key === 'index'">
                                    <span class="icon_num icon_num_all" v-if="index < 3">{{ index + 1 }}</span>
                                    <span class="icon_num_all" v-else>{{ index + 1 }}</span>
                                </template>
                                <!-- 环比 上下icon -->
                                <template v-if="column.key === 'ringNumber'">
                                    <p>
                                        <span v-if="record[column.key] > 0" style="color: #eb2f96;">
                                            <UpCircleTwoTone two-tone-color="#eb2f96" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else-if="record[column.key] < 0" style="color: #41dd57;">
                                            <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </p>
                                </template>

                                <template v-if="column.key === 'signedAmount'">
                                    <p>
                                        {{ '￥' + $formatCurrency(record[column.key]) }}
                                    </p>
                                </template>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
            </a-row>
        </div>
    </div>
    <div v-else class="m-home">
        <div>
            <a-card style="width: 100%; margin-bottom: 16px;">
                <p>客户添加/查重</p>
                <div class="input-box">
                    <input type="text" class="home-input" v-model="wechatOrPhoneNumberContains"
                        placeholder="输入微信号/手机号添加/查重">
                    <span class="hi-button" @click="verifyDuplicate">添加/查重</span>
                </div>
            </a-card>
            <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }" style="margin-bottom: 16px;">
                <a-col :span="24" class="l_con">
                    <a-card title="机构数据">
                        <div @click="toLink('/agency/institutions')" class="greyCon" style="background-color: #F5F4F9;">
                            <a-statistic title="机构总数(家)"
                                :value="numberOfInstitutionsVo.numberOfInstitutions > 0 ? numberOfInstitutionsVo.numberOfInstitutions : '0'">
                                <template #suffix>
                                    家
                                </template>
                            </a-statistic>
                            <!-- <p>开通CRM数 <span>{{ numberOfInstitutionsVo.numberOfOpening > 0 ?
                                numberOfInstitutionsVo.numberOfOpening : '-'
                            }}</span></p> -->
                        </div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
                <a-col :span="24">
                    <a-card class="m-r_lfcon">
                        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                            <p style="margin-right: 10px; font-size: 16px; padding-bottom: 10px; font-weight: bold;">留服客资数据
                            </p>
                            <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                                class="page-btm" style="width: 220px;margin-bottom: 10px;background: #F3F4F8;"
                                v-model:value="value" :placeholder="['开始时间', '结束时间']" @change="getCustomerData" />
                        </div>
                        <div @click="toLink('/newCustomer/institutionsCustomer')" class="greyCon" style="background-color: #EFF7FF;">
                            <a-statistic title="新增客资(条)"
                                :value="customerDataVo.currentNew > 0 ? customerDataVo.currentNew : ''">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentNew" :LastData="customerDataVo.lastNew">
                            </chainIndex>

                        </div>
                        <div @click="toLink('/newCustomer/allocationCustomer')" class="greyCon" style="background-color: #FFF4F5;">
                            <a-statistic title="分配客资(条)"
                                :value="customerDataVo.currentAllocation > 0 ? customerDataVo.currentAllocation : ''">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentAllocation"
                                :LastData="customerDataVo.lastAllocation">
                            </chainIndex>
                        </div>
                        <div @click="toLink('/order/institutionsOrder')" class="greyCon" style="background-color: #EFF7FF;">
                            <a-statistic title="签单数(签)"
                                :value="customerDataVo.currentNumberOfSignedOrders > 0 ? customerDataVo.currentNumberOfSignedOrders : '0'">
                                <template #suffix>
                                    条
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentNumberOfSignedOrders"
                                :LastData="customerDataVo.lastNumberOfSignedOrders">
                            </chainIndex>
                        </div>
                        <div @click="toLink('/order/institutionsOrder')" class="greyCon" style="background-color: #FFF4F5;">
                            <a-statistic title="签单金额(元)"
                                :value="customerDataVo.currentSignedAmount > 0 ? customerDataVo.currentSignedAmount : '0'">
                                <template #prefix>
                                    ￥
                                </template><template #suffix>
                                    元
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentSignedAmount"
                                :LastData="customerDataVo.lastSignedAmount">
                            </chainIndex>

                        </div>
                        <div  @click="toLink('/order/transactions')" class="greyCon" style="background-color: #EFF7FF;">
                            <a-statistic title="回款金额(元)"
                                :value="customerDataVo.currentRevenue > 0 ? customerDataVo.currentRevenue : '0'">
                                <template #prefix>
                                    ￥
                                </template>
                                <template #suffix>
                                    元
                                </template></a-statistic>
                            <chainIndex :CurrentData="customerDataVo.currentRevenue" :LastData="customerDataVo.lastRevenue">
                            </chainIndex>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row style="margin: 20px 0;" :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
                <a-col :span="24">
                    <a-card>
                        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                            <p style="margin-right: 10px; font-size: 16px; padding-bottom: 10px; font-weight: bold;">客资趋势
                            </p>
                            <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                                @change="drawLine" class="page-btm"
                                style="width: 220px;margin-bottom: 10px;background: #F3F4F8;" v-model:value="lineValue"
                                :placeholder="['开始时间', '结束时间']" />
                        </div>
                        <div id="myLine" :style="{ width: '100%', height: '400px' }"></div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row style="margin: 20px 0;" :gutter="{ xs: 8, sm: 16, md: 12, lg: 12 }">
                <a-col :span="24">
                    <a-card>
                        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                            <p style="margin-right: 10px; font-size: 16px; padding-bottom: 10px; font-weight: bold;">客户来源
                            </p>
                            <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                                @change="getCustomerSource" class="page-btm"
                                style="width: 220px;margin-bottom: 10px;background: #F3F4F8;" v-model:value="pieValue"
                                :placeholder="['开始时间', '结束时间']" />
                        </div>
                        <div id="myChart" :style="{ width: '100%', height: '400px' }"></div>
                    </a-card>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="24">
                    <a-card>
                        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                            <p style="margin-right: 10px; font-size: 16px; padding-bottom: 10px; font-weight: bold;">
                                员工有效客资排名</p>
                            <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                                @change="getAssignCustomersData()" class="page-btm"
                                style="width: 220px;margin-bottom: 10px;background: #F3F4F8;"
                                v-model:value="assignCustomersValue" :placeholder="['开始时间', '结束时间']" />
                        </div>
                        <a-table size="middle" :scroll="{ x: 800 }" :pagination="false" bordered :dataSource="dataSource"
                            :columns="columns">
                            <template #bodyCell="{ column, record, index }">
                                <!-- 序号 -->
                                <template v-if="column.key === 'index'">
                                    <span class="icon_num icon_num_all" v-if="index < 3">{{ index + 1 }}</span>
                                    <span class="icon_num_all" v-else>{{ index + 1 }}</span>
                                </template>
                                <!-- 环比 上下icon -->
                                <template v-if="column.key === 'ringNumber'">
                                    <p>
                                        <span v-if="record[column.key] > 0" style="color: #eb2f96;">
                                            <UpCircleTwoTone two-tone-color="#eb2f96" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else-if="record[column.key] < 0" style="color: #41dd57;">
                                            <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </p>
                                </template>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="24" style="margin-top: 16px;">
                    <a-card>
                        <div style="display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between;">
                            <p style="margin-right: 10px; font-size: 16px; padding-bottom: 10px; font-weight: bold;">
                                机构接收客资排名</p>
                            <a-range-picker placement="bottomRight" :popupClassName="$isMobile ? 'rangStyle' : ''"
                                @change="getReceivingCustomersData()" class="page-btm"
                                style="width: 220px;margin-bottom: 10px;background: #F3F4F8;"
                                v-model:value="receivingCustomersValue" :placeholder="['开始时间', '结束时间']" />
                        </div>
                        <a-table size="middle" :scroll="{ x: 800 }" :pagination="false" bordered
                            :dataSource="receivingCustomersDataSource" :columns="receivingCustomersColumns">
                            <template #bodyCell="{ column, record, index }">
                                <!-- 序号 -->
                                <template v-if="column.key === 'index'">
                                    <span class="icon_num icon_num_all" v-if="index < 3">{{ index + 1 }}</span>
                                    <span class="icon_num_all" v-else>{{ index + 1 }}</span>
                                </template>
                                <!-- 环比 上下icon -->
                                <template v-if="column.key === 'ringNumber'">
                                    <p>
                                        <span v-if="record[column.key] > 0" style="color: #eb2f96;">
                                            <UpCircleTwoTone two-tone-color="#eb2f96" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else-if="record[column.key] < 0" style="color: #41dd57;">
                                            <DownCircleTwoTone two-tone-color="#41dd57" style="color: #41dd57;" />
                                            {{ record[column.key] }}
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </p>
                                </template>

                                <template v-if="column.key === 'signedAmount'">
                                    <p>
                                        {{ '￥' + $formatCurrency(record[column.key]) }}
                                    </p>
                                </template>
                            </template>
                        </a-table>
                    </a-card>
                </a-col>
            </a-row>
        </div>
        <a-modal v-model:open="openModal" title="提示">
            <p style="padding: 20px 0;" v-show="type === 1">
                <ExclamationCircleOutlined style="color: #faad14; margin-right: 6px;" />该客资未重复，是否录入
            </p>
            <p style="padding: 20px 0;" v-show="type === 2">客户与<span style="color: #ff4d4f">{{ detail.entryPerson
            }}</span>添加的<span style="color: #ff4d4f">{{ detail.name }}</span>重复！当前客资归属人<span
                    style="color: #ff4d4f">{{
                        detail.attribution
                    }}</span>，客户阶段为<span style="color: #ff4d4f">{{
    getStatus(detail.customerPhase) }}</span>，创建时间：<span style="color: #ff4d4f">{{
        getDate(detail.createdDate)
    }}</span>
            </p>
            <template v-if="type === 1" #footer>
                <a-button @click="close">取消</a-button>
                <a-button type="primary" @click="handleOk">录入</a-button>
            </template>
            <template v-if="type === 2" #footer>
                <a-button type="primary" @click="close">确定</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
import chainIndex from "@/components/home/chainIndex";
import { getProfile } from "../../../utils/session";
import dayjs from "dayjs";
export default {
    name: "Home",
    components: {
        chainIndex
    },
    data() {
        return {
            profile: null,
            value: [],
            pieValue: [],
            lineValue: [],
            assignCustomersValue: [],
            receivingCustomersValue: [],
            myPieChart: null,
            numberOfInstitutionsVo: {},
            customerDataVo: {},
            customerSourceVo: null,
            dataSource: [],
            columns: [],
            receivingCustomersDataSource: [],
            receivingCustomersColumns: [],
            wechatOrPhoneNumberContains: '',
            openModal: false,
            type: 0,
            detail: {},
            permissions: null,
        }
    },
    async mounted() {
        try {
            this.profile = await getProfile();
            this.permissions = this.profile.everyPermissions;
            if (!this.permissions('/home/numberOfInstitutions')) {
                this.$router.push('/')
            } else {
                this.getMouth()
                this.getAgencyCount()
                this.getCustomerData()
                this.getCustomerSource()
                this.drawLine()
                this.getAssignCustomersData()
                this.getReceivingCustomersData()
            }
        } catch ({ message, status }) {
            if (status !== 401) {
                this.$message.error(message);
            }
        }
    },
    onUnmounted() {
        this.myLineChart.dispose()
        this.myPieChart.dispose()
    },
    computed: {
        username() {
            if (this.profile == null) {
                return '...';
            }
            return this.profile.username;
        },
        admin() {
            if (this.profile == null) {
                return '...';
            }
            return this.profile.admin;
        },
        home() {
            if (this.profile == null) {
                return '...';
            }
            return this.profile.home;
        },
    },

    methods: {
        async getAssignCustomersData() {
            let coreDataDateOnOrAfter = this.assignCustomersValue[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.assignCustomersValue[1].format('YYYY-MM-DD 23:59:59')
            try {
                let res = await this.$http.get('/home/assignCustomers', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                if (res.code == '200') {
                    this.columns = [
                        {
                            title: '排名',
                            dataIndex: 'index',
                            key: 'index',
                            width: 50,
                            align: "center"

                        },
                        {
                            title: '员工',
                            dataIndex: 'username',
                            width: 120,
                            key: 'username',
                            ellipsis: true
                        },
                        {
                            title: '录入客资(条)',
                            dataIndex: 'enterTheNumberOfCustomers',
                            width: 90,
                            key: 'enterTheNumberOfCustomers',
                        },
                        {
                            title: '有效客资(条)',
                            dataIndex: 'numberOfEffective',
                            width: 100,
                            key: 'numberOfEffective',
                        },
                        {
                            title: '环比(条)',
                            dataIndex: 'ringNumber',
                            key: 'ringNumber',
                            width: 70,
                            align: "center"
                        },
                        {
                            title: '分配客资(条)',
                            dataIndex: 'numberOfAssignedCustomers',
                            width: 100,
                            key: 'numberOfAssignedCustomers',
                        },
                    ],
                        this.dataSource = res.assignCustomersVo
                }
            } catch ({ message }) {
                this.$message.error(message)
            }
        },
        async getReceivingCustomersData() {
            let coreDataDateOnOrAfter = this.receivingCustomersValue[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.receivingCustomersValue[1].format('YYYY-MM-DD 23:59:59')
            try {
                let res = await this.$http.get('/home/receivingCustomers', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                if (res.code == '200') {
                    this.receivingCustomersColumns = [
                        {
                            title: '排名',
                            dataIndex: 'index',
                            key: 'index',
                            align: "center",
                            width: 50,
                        },
                        {
                            title: '机构名称',
                            dataIndex: 'name',
                            key: 'name',
                            width: 130,
                            ellipsis: true
                        },
                        {
                            title: '接收客资(条)',
                            dataIndex: 'numberOfReceivedCustomers',
                            width: 100,
                            key: 'numberOfReceivedCustomers',
                        },
                        {
                            title: '环比(条)',
                            dataIndex: 'ringNumber',
                            key: 'ringNumber',
                            width: 80,
                            align: "center"
                        },
                        {
                            title: '签单额(元)',
                            dataIndex: 'signedAmount',
                            width: 120,
                            key: 'signedAmount',
                        },
                    ],
                        this.receivingCustomersDataSource = res.receivingCustomersVo
                }
            } catch ({ message }) {
                this.$message.error(message)
            }
        },
        //客资来源
        async getCustomerSource() {
            let coreDataDateOnOrAfter = this.pieValue[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.pieValue[1].format('YYYY-MM-DD 23:59:59')
            try {
                let res = await this.$http.get('/home/customerSource', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                if (res.code == '200') {
                    this.customerSourceVo = res.customerSourceVo
                    const domMap = document.getElementById("myChart");
                    domMap.removeAttribute("_echarts_instance_");

                    this.myPieChart = this.$echarts.init(domMap);
                    let option = {
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}<br/>客资占比 : {d}%'
                        },
                        legend: {
                            orient: 'vertical',
                            left: 'left'
                        },

                        series: [
                            {
                                type: 'pie',
                                radius: '50%',
                                left: "15%",
                                label: {
                                    show: true,
                                    position: 'inside',
                                    formatter: '{d}%',
                                },
                                data: this.customerSourceVo,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            },
                            {
                                type: 'pie',
                                radius: '50%',
                                left: "15%",
                                label: {
                                    formatter: '{b}\n{c}条',
                                },
                                data: this.customerSourceVo,
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    };
                    this.myPieChart.setOption(option);
                }
            } catch ({ message }) {
                this.$message.error(message)
            }

        },
        //机构总数（家）
        async getAgencyCount() {
            try {
                let res = await this.$http.get('/home/numberOfInstitutions');
                if (res.code == '200') {
                    this.numberOfInstitutionsVo = res.numberOfInstitutionsVo
                }
            } catch ({ message }) {
                this.$message.error(message)
            }
        },
        //留服客资数据
        async getCustomerData() {
            let coreDataDateOnOrAfter = this.value[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.value[1].format('YYYY-MM-DD 23:59:59')
            try {
                let res = await this.$http.get('/home/customerData', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                if (res.code == '200') {
                    this.customerDataVo = res.customerDataVo
                }
            } catch ({ message }) {
                this.$message.error(message)
            }
        },
        getMouth() {
            let today = dayjs().format('YYYY-MM-DD')
            let firstDay = today.split('-')[0] + '-' + today.split('-')[1] + '-01'
            firstDay = dayjs(firstDay).format('YYYY-MM-DD' + ' 00:00:00')
            today = dayjs(today).format('YYYY-MM-DD' + ' 23:59:59')
            let month = []
            month[1] = dayjs(today)
            month[0] = dayjs(firstDay)
            this.value = month
            this.pieValue = month
            this.lineValue = month
            this.assignCustomersValue = month
            this.receivingCustomersValue = month
            return month
        },
        generateDateArray(startDate, endDate) {
            let dateArray = [];
            let currentDate = new Date(startDate);
            endDate = new Date(endDate);
            while (currentDate <= endDate) {
                let year = currentDate.getFullYear();
                let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                let day = currentDate.getDate().toString().padStart(2, '0');
                dateArray.push(`${year}-${month}-${day}`);
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return dateArray;
        },
        getLineData(dates, newTrend) {
            const dateValueObj = {};
            dates.forEach(date => {
                dateValueObj[date] = 0;
            });
            newTrend.forEach(item => {
                if (dateValueObj.hasOwnProperty(item.time)) {
                    dateValueObj[item.time] = item.value;
                }
            });
            const resultValues = Object.values(dateValueObj);
            return resultValues
        },
        async drawLine() {
            let start = this.lineValue[0].format('YYYY-MM-DD');
            let end = this.lineValue[1].format('YYYY-MM-DD')
            let dates = this.generateDateArray(start, end)
            let coreDataDateOnOrAfter = this.lineValue[0].format('YYYY-MM-DD 00:00:00');
            let coreDataDateOnOrBefore = this.lineValue[1].format('YYYY-MM-DD 23:59:59')
            try {
                let resNewTrend = await this.$http.get('/home/newTrend', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                let resDistributionTrends = await this.$http.get('/home/distributionTrends', {
                    coreDataDateOnOrAfter, coreDataDateOnOrBefore
                });
                if (resNewTrend.code == '200' && resDistributionTrends.code == '200') {
                    const newTrend = resNewTrend.newTrend
                    const distributionTrends = resDistributionTrends.distributionTrends
                    const newTrendValues = this.getLineData(dates, newTrend)
                    const distributionTrendsValues = this.getLineData(dates, distributionTrends)

                    const domMap = document.getElementById("myLine");
                    domMap.removeAttribute("_echarts_instance_");
                    var myChart = this.$echarts.init(domMap);
                    let option = {
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['新增客资', '分配客资']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            data: dates,
                            // axisLabel: {
                            //   rotate: 60
                            // }
                        },
                        yAxis: {
                            type: 'value'
                        },
                        series: [
                            {
                                name: '新增客资',
                                type: 'line',
                                stack: 'Total',
                                data: newTrendValues,
                                smooth: true,
                                stack: '新增客资'
                            },
                            {
                                name: '分配客资',
                                type: 'line',
                                stack: 'Total',
                                data: distributionTrendsValues,
                                smooth: true,
                                stack: '分配客资'
                            },
                        ]
                    };
                    myChart.setOption(option);
                }
            } catch {

            }
        },

        //获取部门数据
        async verifyDuplicate() {
            if (!this.wechatOrPhoneNumberContains) {
                this.$message.error('请输入微信号/手机号');
                return
            }
            try {
                let res = await this.$http.get('/verifyDuplicate', {
                    wechatOrPhoneNumberIs: this.wechatOrPhoneNumberContains
                });
                if (res.code == '200') {
                    this.detail = res;
                    this.type = 2;
                    this.openModal = true;
                } else if (res.code == '201') {
                    this.type = 1;
                    this.openModal = true;
                }
            } catch ({ message }) {

            }
        },

        handleOk() {
            this.$router.push({
                path: '/customer/institutionsCustomer',
                query: {
                    type: 'add',
                    wp: this.wechatOrPhoneNumberContains
                }
            })
            this.wechatOrPhoneNumberContains = '';
            this.openModal = false;
        },

        close() {
            this.wechatOrPhoneNumberContains = '';
            this.openModal = false;
        },

        getStatus(val) {
            switch (val) {
                case 'NEW_CUSTOMER_CAPITAL':
                    return '新客咨';
                case 'CONTACTS':
                    return '已联系上';
                case 'IN_INTENTION':
                    return '确定意向中';
                case 'TO_BE_ALLOCATED':
                    return '待分配';
                case 'ALLOCATED':
                    return '已分配';
                case 'MULTIPLE_ASSIGNMENT':
                    return '多次分配';
                default:
                    break
            }
        },

        getDate(val) {
            return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
        },

        toLink(path) {
            this.$router.push(path);
        },

    }
}
</script>

<style scoped lang="scss">
.welcome-h {
    margin: 100px 0 0 100px;
    font-size: 25px;
    display: inline-block;

    span {
        color: #007BEF;
        font-weight: bold;
    }

    p {
        font-size: 15px;
        color: #999999;
        text-align: right;
        margin-right: 22px;
    }
}



.greyCon {
    background-color: #f1f1f1;
    padding: 15px;
    min-width: 130px;
}

:deep(.r_lfcon .ant-card-body) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .greyCon {
        width: 22%;
        margin: 0 10px;
        min-width: 220px;
    }
}

:deep(.ant-statistic-content-prefix) {
    font-size: 14px;
}

:deep(.ant-statistic-content-suffix) {
    font-size: 14px;
}

:deep(.ant-table-wrapper .ant-table-thead >tr>th) {
    background: none;
}

.icon_num {
    background-color: #1156d6 !important;
    color: #fff;
}

.icon_num_all {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    background-color: #ddd;

}

.f_con {
    :deep(.ant-card-body) {
        height: 330px;
        min-height: 330px;
    }
}

:deep(.ant-card-body) {
    overflow: auto;

    &::-webkit-scrollbar {
        width: 6px; // 横向滚动条
        height: 6px; // 纵向滚动条 必写
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ddd;
        border-radius: 3px;
    }
}

:deep(.m-r_lfcon .ant-card-body) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .greyCon {
        width: 100%;
        margin-bottom: 10px;
    }
}

.m-home {

    .m-welcome-h {
        padding: 20px;
    }

    :deep(.ant-card-body) {
        padding: 10px;
    }

    :deep(.ant-card-head) {
        padding: 6px;
    }

    .input-box {
        margin-top: 8px;
        display: flex;
        align-items: center;
    }

    .home-input {
        background: none;
        outline: none;
        border: none;
        width: 170px;
        background-color: #E8EDFF;
        padding: 8px 12px;
        border-radius: 4px;
    }

    .hi-button {
        background-color: #6672FB;
        padding: 4px 12px;
        border-radius: 4px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 12px;
    }
}

:deep(.ant-table-thead > tr >th) {
    background: #E8EDFF !important;
}
</style>
<style lang="scss">
.rangStyle {
    .ant-picker-panels {
        display: inline-flex;
        flex-wrap: nowrap;
        direction: ltr;
        flex-direction: column;
    }
}
</style>